<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <b-modal
      v-if="
        $store.state.app.token &&
        $store.state.app.workspaces &&
        $store.state.app.workspaces !== null
      "
      ref="my-modal"
      hide-footer
      modal-class="modal-warning"
      :no-close-on-backdrop="true"
      ok-only
      ok-variant="secondary"
    >
      <template #modal-header="{}">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="text-warning mb-0">
            <feather-icon icon="AlertTriangleIcon" class="mr-25" />Warning
          </h5>
          <b-button
            @click="logout"
            size="sm"
            variant="outline-primary"
            class="m-25"
          >
            Logout</b-button
          >
        </div>
      </template>
      <div
        class="d-flex flex-column align-items-center justify-content-start mb-1"
      >
        <h5>Please choose an organization to access the selected page.</h5>
        <v-select
          v-model="selectedCid"
          label="name"
          :options="workspaces"
          autocomplete
          style="width: 400px"
          :reduce="(wrkspc) => wrkspc.tenant_uid"
          class="mt-50"
          placeholder="--Search Organization--"
        />
      </div>
    </b-modal>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useWindowSize, useCssVar } from "@vueuse/core";
import { BModal, BButton } from "bootstrap-vue";
import store from "@/store";
import router from "@/router";
const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
import vSelect from "vue-select";

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BModal,
    BButton,
    vSelect,
  },
  data() {
    return {
      // workspaces: [],
      organizations: [],
      loading: false,
      selectedCid: null,
    };
  },

  mounted: function () {
    this.$store.state.app.user.permissions;
    // this.workspaces = this.$store.state.app.workspaces;

    window.setInterval(() => {
      // console.log("check cookies");

      if (
        $cookies.get("token") &&
        $cookies.get("token") == "null" &&
        this.$store.state.app.token &&
        this.$store.state.app.token !== null
      ) {
        this.$store.dispatch("notification/resetNotifications");
        this.$store.dispatch("app/logout");
        if (this.$route.name != "login") this.$router.push("/login");
      }

      if (
        $cookies.get("token") &&
        $cookies.get("token") != "null" &&
        $cookies.get("token") != this.$store.state.app.token
      ) {
        if (
          $cookies.get("uid") &&
          $cookies.get("uid") != "null" &&
          $cookies.get("uid") != this.$store.state.app.uid
        ) {
          this.$store.commit("app/auth_success", $cookies.get("token"));
          this.$store.dispatch("app/currenUser").then((response) => {
            const redirectableRoutes = [
              "/login",
              "/app-switch",
              "/error-404",
              "/no-workspace",
              "/register",
            ];

            if (this.checkWorkspacesExist()) {
              if (redirectableRoutes.includes(this.$route.path))
                this.$router.push("/").then(() => {
                  this.handleWorkspaceNotSelected();
                });
            } else {
              this.$router.push("/no-workspace");
            }
          });

          // this.$router.push("/app-switch").then(() => {
          //   this.$store.commit("app/auth_success", $cookies.get("token"));

          //   this.$store.dispatch("app/currenUser").then((response) => {
          //     if (
          //       this.$store.state.app.org_id &&
          //       this.$store.state.app.org_id != null
          //     ) {
          //       this.$store.dispatch(
          //         "app/getOrgRefresh",
          //         this.$store.state.app.org_id
          //       );
          //       this.$store.dispatch("notification/getAllNotifications", {});
          //     }
          //     // this.$store.dispatch("app/checkIfUserChanged");
          //     if (this.$store.state.app.workspaces.length === 0) {
          //       this.$router.push({ name: "noworkspace" });
          //     }
          //   });
          // });
        } else {
          this.$store.commit("app/auth_success", $cookies.get("token"));
          this.$store.dispatch("app/currenUser").then((response) => {
            if (
              this.$store.state.app.org_id &&
              this.$store.state.app.org_id != null
            ) {
              this.$store.dispatch(
                "app/getOrgRefresh",
                this.$store.state.app.org_id
              );
              this.$store.dispatch("notification/getAllNotifications", {});
            }
            // this.$store.dispatch("app/checkIfUserChanged");
            if (this.$store.state.app.workspaces.length === 0) {
              this.$router.push({ name: "noworkspace" });
            }
          });
        }
      }

      if (
        $cookies.get("token") &&
        $cookies.get("token") != "null" &&
        $cookies.get("token") == this.$store.state.app.token &&
        this.workspaces &&
        typeof this.workspaces != "undefinded" &&
        this.workspaces != null &&
        this.workspaces.length > 0 &&
        this.$store.state.app.org_id == null
      ) {
        this.handleWorkspaceNotSelected();
      }

      if (
        $cookies.get("token") &&
        $cookies.get("token") != "null" &&
        $cookies.get("token") == this.$store.state.app.token &&
        $cookies.get("uid") &&
        $cookies.get("uid") != "null" &&
        $cookies.get("uid") == this.$store.state.app.uid &&
        this.workspaces &&
        typeof this.workspaces != "undefinded" &&
        this.workspaces != null &&
        this.workspaces.length > 0 &&
        this.$store.state.app.org_id != null
      ) {
        if (this.checkIfRedirectable()) {
          this.handleRedirectPath();
        }
        this.$store.commit("app/SET_ACCEPT_REDIRECT_PATH", false);
        const redirectableRoutes = [
          "/login",
          "/app-switch",
          "/error-404",
          "/no-workspace",
          "/register",
        ];

        // if (
        //   this.$store.state.app.currentWorkspace &&
        //   typeof this.$store.state.app.currentWorkspace.onboardning_status ==
        //     "undefined" &&
        //   this.$route.path != "/on-boarding"
        // ) {
        //   this.$router.push("/on-boarding").then((res) => {
        //     // this.$store.dispatch("app/getOrg", this.workspaces[0].tenant_uid);
        //     this.$store.dispatch("app/currenUser").then(() => {
        //       window.location.reload();
        //     });
        //   });
        // } else if (
        //   this.$store.state.app.currentWorkspace &&
        //   this.$store.state.app.currentWorkspace.onboardning_status != 1 &&
        //   this.$route.path != "/on-boarding"
        // ) {
        //   this.$router.push("/on-boarding").then((res) => {
        //     this.$store.dispatch("app/currenUser").then(() => {
        //       window.location.reload();
        //     });
        //   });
        // } else
        if (redirectableRoutes.includes(this.$route.path)) {
          this.$router.push("/");
        }
      }

      if (this.$store.state.app.expiry) {
        if (
          this.$route.name != "expired" &&
          this.$route.name != "login" &&
          this.$route.name != "forgot-password-form"
        ) {
          this.$router.push("/expired");
        }
      }

      if (this.$store.state.app.is_onboading_completed === false) {
        this.$router.push("/on-boarding");
      }
      if (
        this.$router.currentRoute.path === "/on-boarding" &&
        this.$store.state.app.is_onboading_completed === true
      ) {
        this.$router.push("/");
      }

      // else if (
      //   $cookies.get("token") &&
      //   $cookies.get("token") != "null" &&
      //   (!this.$store.state.app.org_id || this.$store.state.app.org_id == null)
      // ) {
      //   let stringifiedWorkspaces = $cookies.get("workspaces");
      //   // typeof stringifiedWorkspaces
      //   // debugger;
      //   // const workspaces = JSON.parse(stringifiedWorkspaces);
      //   console.log("StringifiedWorkspaces", stringifiedWorkspaces);
      //   this.$store.commit("app/set_workspace", stringifiedWorkspaces);
      //   this.$store.commit("app/auth_success", $cookies.get("token"));
      //   // this.$store.dispatch("app/currenUser").then((response) => {
      //   //   if (
      //   //     this.$store.state.app.org_id &&
      //   //     this.$store.state.app.org_id !== null
      //   //   ) {
      //   //     this.$store.dispatch(
      //   //       "app/getOrgRefresh",
      //   //       this.$store.state.app.org_id
      //   //     );
      //   //   }
      //   //   this.$router.push("/");
      //   // });
      // }
    }, 1000);
  },
  created: function () {
    var self = this;
    setInterval(function () {
      var timeNow = new Date();
      var timeLoggedIn = new Date(localStorage.getItem("loggedInTime"));
      var minutes = (timeNow.getTime() - timeLoggedIn.getTime()) / 1000 / 60;
      console.log("time", Math.ceil(minutes));
      minutes = Math.ceil(minutes);
      if (minutes > 5 && minutes < 55) {
        self.$store.dispatch("app/refresh", self.$store.state.app.token);
      }
      // self.$store.dispatch("app/getOrgRefresh", self.$store.state.app.org_id);
    }, 60000);
    //Adding authorization token
    this.$http.interceptors.request.use(
      (config) => {
        // Temporary interceptior to attach org-id as params for all asset/vulns endpoint
        if (config.url.indexOf("asset/vulns") > -1) {
          if (config.params) {
            // Add parameter only if no parameter named org_id is not already defined
            // and url doesn't contain an org_id already
            if (
              !"org_id" in config.params &&
              this.$store.state.app.org_id &&
              config.url.indexOf("org_id") == -1
            ) {
              config.params = {
                ...config.params,
                org_id: this.$store.state.app.org_id,
              };
            }
          } else {
            // If no parameters are defined already, add org_id
            if (config.url.indexOf("org_id") == -1)
              config.params = { org_id: this.$store.state.app.org_id };
          }
        }
        // See how much time is left to expire the current token.
        const token = this.$store.state.app.token;
        const cid = this.$store.state.app.org_id;
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
          if (cid) {
            config.headers["org-id"] = cid;
          }
        }
        // config.headers['Content-Type'] = 'application/json';
        //console.log("url config after",config)
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //If API response is 401, redirect to login page
    this.$http.interceptors.response.use(
      function (response) {
        if (response.data.status === "error") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.loading = false;
        }
        return response;
      },
      function (error) {
        // err = error.response
        return new Promise(function (resolve, reject) {
          // if(error.data.detail == "Token has expired."){
          //   store.dispatch("app/set_expiry");
          // }

          if ("detail" in error.response.data) {
            if (error.response.data.detail == "Token has expired.")
              store.dispatch("app/setExpiry", true).then((res) => {
                if (store.state.app.expiry && route.name != "expired")
                  router.push("/expired");
              });
          }
          if (
            error.response.status === 401 &&
            error.response.config &&
            !error.response.config.__isRetryRequest
          ) {
            if (
              $cookies.get("token") != "null" &&
              $cookies.get("uid") != "null"
            ) {
              router.push({ name: "noworkspace" });
            } else {
              this.$store.dispatch("notification/resetNotifications");
              store.dispatch("app/logout");
              router.push("/login");
            }
          }
          if (error.response.status === 403) {
            router.push("/access-denied");
          }
          if (error.response.status === 402) {
            router.push({
              name: "nofeature",
              query: { errorMessage: error.response.data.message },
            });
          }
          if (error.response.status === 400) {
            if (
              error.response.data.non_field_errors &&
              error.response.data.non_field_errors.length > 0
            ) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.non_field_errors[0],
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else if (error.response.data.url) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.url[0],
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else if (error.response.data.description) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.description[0],
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else if (error.response.data.status === "error") {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else {
              if (error.response.data[0]) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data[0],
                    autoHideDelay: 5000,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              } else if (error.response.data.error) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    autoHideDelay: 5000,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              } else if (error.response.data.username[0]) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.username[0],
                    autoHideDelay: 5000,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              } else if (error.response.data.asset_name[0]) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.asset_name[0],
                    autoHideDelay: 5000,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Something went wrong",
                    autoHideDelay: 5000,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              }

              // Handle the case when the 'non_field_errors' property is empty or undefined
            }
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: error.response.data.non_field_errors[0],
            //     autoHideDelay: 5000,
            //     icon: "EditIcon",
            //     variant: "danger",
            //   },
            // });
          }
          if (error.response.status === 500) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Server Error",
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }

          throw error.response;
        });
      }
    );
  },
  watch: {
    $route() {
      if (this.$route.meta.pageTitle != "Vulnerabilities") {
        let coll = false;
        this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
      }
    },
    selectedCid(newValue, oldValue) {
      this.hideModal();
      if (newValue && newValue != null) {
        this.$store.dispatch("app/getOrg", newValue);
        this.workspaces.map((item) => {
          if (item.tenant_uid === newValue) {
            this.$store.dispatch("notification/getAllNotifications", {});
            // const currentmodule = [];
            // item.modules.map((mod) => {
            //   currentmodule.push(mod.name);
            // });

            // this.$store.commit("app/set_currentModules", currentmodule);
            // this.$store.commit("app/set_currentWorkspace", item);
            if (this.checkIfRedirectable()) {
              this.handleRedirectPath();
            }
            this.$store.commit("app/SET_ACCEPT_REDIRECT_PATH", false);

            const redirectablePaths = [
              "/login",
              "/app-switch",
              "/no-workspace",
            ];
            if (redirectablePaths.includes(this.$route.path)) {
              this.$router.push("/").then((res) => {
                this.$store.dispatch("app/getOrgRefresh");
                this.$store.dispatch("app/currenUser").then(() => {
                  window.location.reload();
                });
              });
            } else {
              this.$store.dispatch("app/getOrgRefresh");
              this.$store.dispatch("app/currenUser").then(() => {
                window.location.reload();
              });
            }
          }
        });
      } else if (newValue == null && this.$route.name != "expired") {
        window.location.reload();
      }
    },
    // Set a watcher for org_id and reload the page if org_id is null
    // root_org_id(newV, oldV) {
    //   if (newV == null && this.$route.name != "expired")
    //     window.location.reload();
    // },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    root_org_id: {
      get() {
        return this.$store.state.app.org_id;
      },
      set(value) {
        this.$store.dispatch("app/getOrg", value);
        this.workspaces.map((item) => {
          if (item.tenant_uid === value) {
            this.$store.commit("app/set_currentWorkspace", item);
            const currentmodule = [];
            item.enabled_modules.map((mod) => {
              currentmodule.push(mod.name);
            });
            this.$store.commit("app/set_currentModules", currentmodule);
          }
        });
        // if (this.$store.state.app.user.id != 1) {
        //   this.workspaces.map((item) => {
        //     if (item.tenant_uid === value) {
        //       this.$store.commit("app/set_currentWorkspace", item);
        //       const currentmodule = [];
        //       item.modules.map((mod) => {
        //         currentmodule.push(mod.name);
        //       });
        //       this.$store.commit("app/set_currentModules", currentmodule);
        //     }
        //   });
        // }
      },
    },
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    workspaces() {
      return this.$store.state.app.workspaces;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark")
      document.body.classList.add("dark-layout") -
        // Provide toast for Composition API usage
        // This for those apps/components which uses composition API
        // Demos will still use Options API for ease
        {
          hideProgressBar: true,
          closeOnClick: false,
          closeButton: false,
          icon: false,
          timeout: 3000,
          transition: "Vue-Toastification__fade",
        };

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },

  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    handleRedirectPath() {
      const path = this.$cookies.get("redirectTMPath");
      this.$cookies.set(
        "redirectTMPath",
        null,
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      this.$cookies.remove("redirectTMPath");
      this.$store.commit("app/SET_ACCEPT_REDIRECT_PATH", false);
      this.$router.push(path).then((res) => {
        this.$store.dispatch("app/getOrgRefresh");
        this.$store.dispatch("app/currenUser").then(() => {
          window.location.reload();
        });
      });
    },

    checkIfRedirectable() {
      if (
        this.$store.state.app.acceptRedirectPath &&
        this.$cookies.isKey("redirectTMPath") &&
        this.$cookies.get("redirectTMPath") &&
        this.$cookies.get("redirectTMPath") != "null"
      ) {
        return true;
      }
      return false;
    },

    logout: function () {
      this.$store.dispatch("notification/resetNotifications");
      this.$store.dispatch("app/logout").then(() => {
        this.$router.push("/login");
      });
    },

    checkWorkspacesExist() {
      if (
        !this.workspaces ||
        this.workspaces == null ||
        typeof this.workspaces == "undefined" ||
        this.workspaces.length < 1
      ) {
        return false;
      }
      return true;
    },

    handleWorkspaceNotSelected() {
      if (
        !this.workspaces ||
        this.workspaces == null ||
        typeof this.workspaces == "undefined" ||
        this.workspaces.length < 1
      ) {
        this.$router.push("/no-workspace");
      } else if (this.workspaces.length == 1) {
        this.hideModal();
        this.$store.dispatch("app/getOrg", this.workspaces[0].tenant_uid);
        this.workspaces.map((item) => {
          if (item.tenant_uid === this.workspaces[0].tenant_uid) {
            this.$store.dispatch("notification/getAllNotifications", {});
            this.$store.commit("app/set_currentWorkspace", item);
            const currentmodule = [];
            item.enabled_modules.map((mod) => {
              currentmodule.push(mod.name);
            });
            this.$store.commit("app/set_currentModules", currentmodule);
          }
        });
        // this.$store.dispatch("app/changetenant", this.workspaces[0]).then(
        //   (response) => {
        //     this.handleTenantSelected();
        //     this.$store.dispatch("notification/getAllNotifications", {});
        //   },
        //   (error) => {
        //     console.log("Something went wrong");
        //   }
        // );
      } else {
        this.showModal();
      }
    },

    // checkIfOrganizationSelected() {
    //   if (this.$store.state.app.org_id === null) {
    //     this.getWorkSpaces();
    //     this.showModal();
    //   }
    //   // this.getOrganisationDetail();
    // },
    orgaizationSwitcher() {
      this.$store
        .dispatch("notification/getAllNotifications", {})
        .then((res) => {});
    },
    getWorkSpaces() {
      var self = this;
      this.workspaces.forEach(function (value, key) {
        let org = {
          value: self.workspaces[key].tenant_uid,
          text: self.workspaces[key].name,
        };
        self.organizations.push(org);
      });
    },
  },
  beforeDestroy() {
    let channel =
      "tm_" +
      this.$store.state.app.org_id +
      "_user_" +
      this.$store.state.app.user.id +
      "_channel";
    this.sockets.unsubscribe(channel);
    console.log("Un-Subscribed from channel", channel);
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

export default [
  {
    path: "/asset-discovery/",
    name: "Asset Discovery",
    component: () => import("@/views/AssetDiscovery/scan.vue"),
    meta: {
      pageTitle: "List",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Domain Scan",
          active: true,
        },
      ],
    },
  },

  // email harvest
  {
    path: "/email-harvest/",
    name: "Email Harvest",
    component: () => import("@/views/Email-Harvest/index.vue"),
    meta: {
      pageTitle: "Email Discovey",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Index",
          active: true,
        },
      ],
    },
  },

  {
    path: "/email-discovery/add",
    name: "Add Email",
    component: () => import("@/views/Email-Harvest/addEmail.vue"),
    meta: {
      pageTitle: "Add Email",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  //Start asset-types
  {
    path: "/asset-types/",
    name: "AssetTypes",
    component: () => import("@/views/AssetType/index.vue"),
    meta: {
      pageTitle: "Asset Types",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Asset Types",
          active: true,
        },
      ],
    },
  },
  {
    path: "/asset-type/add",
    name: "Add AssetTypes",
    component: () => import("@/views/AssetType/add.vue"),
    meta: {
      pageTitle: "Add Asset Type",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add Asset Type",
          active: true,
        },
      ],
    },
  },
  {
    path: "/asset-type/edit/:id",
    name: "Edit AssetTypes",
    component: () => import("@/views/AssetType/edit.vue"),
    meta: {
      pageTitle: "Edit Asset Type",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Edit Asset Type",
          active: true,
        },
      ],
    },
  },
  // End of asset types

  //Start assets
  {
    path: "/assets/",
    name: "Assets",
    component: () => import("@/views/Asset/index.vue"),
    // component: () => import('@/views/Asset/index_as_tabs.vue'),
    meta: {
      pageTitle: "Assets",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Assets",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/add",
    name: "Add Asset",
    component: () => import("@/views/Asset/add.vue"),
    meta: {
      pageTitle: "Add Asset",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add Asset",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/edit/:id",
    name: "Edit Asset",
    component: () => import("@/views/Asset/edit.vue"),
    meta: {
      pageTitle: "Edit Asset",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Edit Asset",
          active: true,
        },
      ],
    },
  },
  {
    // path: '/assets/scan/:asset_id',
    path: "/assets/scan/:id",
    name: "Scan Asset",
    // component: () => import('@/views/Asset/scan.vue'),
    component: () => import("@/views/Asset/scan_new.vue"),
    meta: {
      pageTitle: "Asset Overview",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Scan",
          active: true,
        },
      ],
    },
  },

  {
    path: "/scans/scansummary/:id",
    name: "Scan Summary",
    // component: () => import('@/views/Asset/scan.vue'),
    component: () => import("@/views/Scan/components/scanSummary.vue"),
    meta: {
      pageTitle: "Scan Summary",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Scan Summary",
          active: true,
        },
      ],
    },
  },
  // {
  //     path: '/assets/attach-scanner/:id',
  //     name: 'Attach Scanner',
  //     component: () =>
  //         import ('@/views/Asset/attach_scanner.vue'),
  //     meta: {
  //         pageTitle: 'Asset',
  //         requiresAuth: true,
  //         breadcrumb: [{
  //             text: 'Attach Scanner',
  //             active: true,
  //         }, ],
  //     },
  // },
  //End of assets
  //start of Vulnerabilities
  // {
  //   path: "/assets/pt-vulnerabilities",
  //   name: "PT Vulnerabilities",
  //   component: () => import("@/views/Vulnerability/list.vue"),
  //   meta: {
  //     pageTitle: "Vulnerabilities",
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: "PT Findings",
  //         active: true,
  //       },
  //     ],
  //   },
  //   props: (route) => ({
  //     exploited: route.query.exploited === "true",
  //   }),
  // },

  {
    path: "/assets/pt-vulnerabilities",
    name: "PT Vulnerabilities",
    component: () => import("@/views/PTVulnerability/list.vue"),
    meta: {
      pageTitle: "Vulnerabilities",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "PT Findings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/vulnerabilities",
    name: "All Vulnerabilities",
    component: () => import("@/views/Vulnerability/list.vue"),
    meta: {
      pageTitle: "Vulnerabilities",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "All Vulnerabilities",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/vulnerabilities/summary",
    name: "Vulnerability Summary",
    component: () =>
      import("@/views/Vulnerability/groupedVuln/groupedVulnSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/pt-vulnerabilities/summary",
    name: "Vulnerability Summary",
    component: () =>
      import("@/views/PTVulnerability/groupedVuln/groupedVulnSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/:id/add-vulnerability",
    name: "Add Vulnerability",
    component: () => import("@/views/Vulnerability/add_v1.vue"),
    meta: {
      pageTitle: "Add Vulnerability",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vulnerability/detail/:id",
    name: "View Vulnerability",
    component: () => import("@/views/Vulnerability/detail.vue"),
    meta: {
      // pageTitle: 'Vulnerability',
      requiresAuth: true,
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      // breadcrumb: [
      //   {
      //     text: 'Detail',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/vulnerability/edit/:id",
    name: "Edit Vulnerability",
    component: () => import("@/views/Vulnerability/edit.vue"),
    meta: {
      pageTitle: "Vulnerability",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Update",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vulnerability/add-vulnerability",
    name: "Add Vulnerability",
    component: () => import("@/views/Vulnerability/addVulnMultiAsset.vue"),
    meta: {
      pageTitle: "Add Vulnerability",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  //Start CVD
  {
    path: "/cvd",
    name: "cvd",
    component: () => import("@/views/cvd/list.vue"),
    meta: {
      pageTitle: "",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "All Vulnerabilities",
          active: true,
        },
      ],
    },
  },
  {
    path: "/cvd/add",
    name: "cvd_add",
    component: () => import("@/views/cvd/add.vue"),
    meta: {
      pageTitle: "",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add Vulnerability",
          active: true,
        },
      ],
    },
  },
  {
    path: "/cvd/vulnerability/detail/:id",
    name: "cvd_detail",
    component: () => import("@/views/cvd/detail.vue"),
    meta: {
      pageTitle: "CVD",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/cvd/vulnerability/edit/:id",
    name: "cvd_edit",
    component: () => import("@/views/cvd/edit.vue"),
    meta: {
      pageTitle: "CVD",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  //Start assets
  {
    path: "/assets/group",
    name: "Groups",
    component: () => import("@/views/AssetGroup/index.vue"),
    meta: {
      pageTitle: "Asset Groups",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/group/add",
    name: "Add Group",
    component: () => import("@/views/AssetGroup/add.vue"),
    meta: {
      pageTitle: "Asset Groups",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/group/:id/add-asset",
    name: "Add Asset To Group",
    component: () => import("@/views/AssetGroup/edit.vue"),
    meta: {
      pageTitle: "Asset Groups",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assets/group/:id/assets",
    name: "Group Assets",
    component: () => import("@/views/AssetGroup/group_assets.vue"),
    meta: {
      pageTitle: "Asset Group",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Assets",
          active: true,
        },
      ],
    },
  },
  //add nessus report
  {
    path: "/assets/upload-nessus-report",
    name: "Upload Nessus Report",
    component: () => import("@/views/Asset/add_nessus.vue"),
    meta: {
      pageTitle: "Upload Report",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Vulnerabilities",
          active: true,
        },
      ],
    },
  },
  //add nessus report from group
  {
    path: "/assets/upload-vuln-report/:org_id/:group_id",
    name: "Upload Nessus Vulnerability",
    component: () => import("@/views/Asset/add_nessus.vue"),
    meta: {
      pageTitle: "Upload Report",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Nessus",
          active: true,
        },
      ],
    },
  },

  // add change asset owner
  {
    path: "/assets/change-asset-owner",
    name: "Change Asset Owner",
    component: () => import("@/views/Asset/changeAssetOwner.vue"),
    meta: {
      pageTitle: "Change Asset Owner",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Asset Owner",
          active: true,
        },
      ],
    },
  },
  //add Acunetix report
  {
    path: "/assets/upload-acunetix-report/:asset_id",
    name: "Upload Acunetix Report",
    component: () => import("@/views/Asset/components/scan/UploadAcunetix.vue"),
    meta: {
      pageTitle: "Upload Acunetix Report",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Acunetix",
          active: true,
        },
      ],
    },
  },

  // Start Attack Surface
  {
    path: "/attack-surface/",
    name: "Attack-Surface",
    component: () => import("@/views/AttackSurface/index.vue"),
    meta: {
      pageTitle: "Attack Surface",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Index",
          active: true,
        },
      ],
    },
  },
  {
    path: "/attack-surface/scan-summary/:id",
    name: "Attack Surface Summary",
    component: () =>
      import("@/views/AttackSurface/components/ASMScanSummary.vue"),
    meta: {
      pageTitle: "Scan Summary",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  // End Attack Surface

  // Start Cloud config
  {
    path: "/cloud-configuration/",
    name: "Cloud-Configuration",
    component: () => import("@/views/CloudConfiguration/index.vue"),
    meta: {
      pageTitle: "Cloud Configuration",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Index",
          active: true,
        },
      ],
    },
  },
  {
    path: "/cloud-configuration/add",
    name: "Add Cloud Configuration",
    component: () => import("@/views/CloudConfiguration/add.vue"),
    meta: {
      pageTitle: "Cloud Configuration",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  // End Cloud config

  // Start Code Review
  {
    path: "/code-review/",
    name: "Code-Review",
    component: () => import("@/views/CodeReview/index.vue"),
    meta: {
      pageTitle: "Code Review",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Index",
          active: true,
        },
      ],
    },
  },
  {
    path: "/code-review/add",
    name: "Code-Review-Add",
    component: () => import("@/views/CodeReview/component/add.vue"),
    meta: {
      pageTitle: "Code Review",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  // End Code Review
];

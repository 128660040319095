import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import asset from "./routes/asset";
// Vue.forceUpdate()
Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...asset,
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Dashboard/v1.vue"),
      // component: () => import("@/views/Task/TaskIndexStable.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
    },
    {
      path: "/inbox",
      name: "inbox",
      component: () => import("@/views/Inbox/Inbox.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
    },
    {
      path: "/dashboard",
      name: "v1-dashboard",
      component: () => import("@/views/Dashboard/v1.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
    },
    {
      path: "/dashboard/domain-list",
      name: "domain-list",
      component: () => import("@/views/Dashboard/domainsList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard/baseline-list",
      name: "baseline-list",
      component: () => import("@/views/Dashboard/baselineList.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
    },
    // quarterly dashboard start
    {
      path: "/quarterly-dashboard",
      name: "quarterly-dashboard",
      component: () => import("@/views/QuarterlyDash/index.vue"),
      meta: {
        pageTitle: "Quarterly Dashboard",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    // quarterly dashboard end
    {
      path: "/task/",
      name: "task",
      component: () => import("@/views/Task/TaskIndexStable.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
    },
    {
      path: "/task/:type",
      name: "task-search",
      component: () => import("@/views/Task/TaskIndexStable.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
      beforeEnter(to, _, next) {
        if (
          [
            "all",
            "mine",
            "team",
            "retest",
            "all-org-task",
            "deleted-task",
          ].includes(to.params.type)
        )
          next();
        else next({ name: "error-404" });
        // if ([0,1,2].includes(to.params.filter)) next()
        // else next({ name: 'error-404' })
      },
    },
    {
      path: "/task/team/:id",
      name: "team-tasks",
      component: () => import("@/views/Task/TaskIndexStable.vue"),
      meta: {
        requiresAuth: true,
        // contentRenderer: 'sidebar-left',
        // contentClass: 'todo-application',
      },
    },

    // edited by alwin
    // {
    //     path: '/task-stable/',
    //     name: 'task-stable',
    //     component: () =>
    //         import ('@/views/Task/TaskIndexStable.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         contentRenderer: 'sidebar-left',
    //         contentClass: 'todo-application',
    //     },
    // },
    // end edit alwin
    //User routes

    {
      path: "/users/",
      name: "Users",
      component: () => import("@/views/User/index.vue"),
      meta: {
        pageTitle: "Users",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/add",
      name: "Add User",
      component: () => import("@/views/User/add.vue"),
      meta: {
        pageTitle: "Add User",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add User",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/edit/:id",
      name: "Edit User",
      component: () => import("@/views/User/edit.vue"),
      meta: {
        pageTitle: "Edit User",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit User",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/reset-password",
      name: "Reset Password",
      component: () => import("@/views/User/reset_password.vue"),
      meta: {
        pageTitle: "Reset Password",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reset",
            active: true,
          },
        ],
      },
    },
    //####################################
    {
      path: "/user/my-logs",
      name: "My Logs",
      component: () => import("@/views/User/MyLogs.vue"),
      meta: {
        pageTitle: "My Logs",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/logs/",
      name: "Logs",
      component: () => import("@/views/Logs/Logs.vue"),
      meta: {
        pageTitle: "Logs",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    // Role routes//
    //User routes
    {
      path: "/roles/",
      name: "Roles",
      component: () => import("@/views/Role/index.vue"),
      meta: {
        pageTitle: "Roles",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles/add",
      name: "Add Role",
      component: () => import("@/views/Role/add.vue"),
      meta: {
        pageTitle: "Role",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles/edit/:id",
      name: "Edit Role",
      component: () => import("@/views/Role/edit.vue"),
      meta: {
        pageTitle: "Role",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    // Organization routes//
    {
      path: "/organizations/",
      name: "Organizations",
      component: () => import("@/views/Organization/Index.vue"),
      meta: {
        pageTitle: "Organizations",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Organizations",
            active: true,
          },
        ],
      },
    },
    {
      path: "/organizations/add",
      name: "Add Organization",
      component: () => import("@/views/Organization/Add.vue"),
      meta: {
        pageTitle: "Add Organization",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add Organization",
            active: true,
          },
        ],
      },
    },
    {
      path: "/organization/edit/:id",
      name: "Edit Organization",
      component: () => import("@/views/Organization/Edit.vue"),
      meta: {
        pageTitle: "Edit Organization",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit Organization",
            active: true,
          },
        ],
      },
    },
    {
      path: "/organization/allocate/:id", //Organization ID it is
      name: "Allocated Scanners",
      component: () => import("@/views/Organization/default_scanner/index.vue"),
      meta: {
        pageTitle: "Default Scanners",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Default Scanners",
            active: true,
          },
        ],
      },
    },
    {
      path: "/organization/allocate/:id", //Organization ID it is
      name: "Allocate New Scanner",
      component: () =>
        import("@/views/Organization/default_scanner/allocate_scanner.vue"),
      meta: {
        pageTitle: "Add default scanner",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Default Scanners",
            active: true,
          },
        ],
      },
    },
    {
      path: "/organization/allocation/edit/:id", //Allocation ID it is
      name: "Edit Allocation",
      component: () =>
        import("@/views/Organization/default_scanner/edit_allocation.vue"),
      meta: {
        pageTitle: "Edit Allocation",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit Allocation",
            active: true,
          },
        ],
      },
    },
    // End of organization

    //Scanner routes
    {
      path: "/scanners/",
      name: "Scanners",
      component: () => import("@/views/Scanner/index.vue"),
      meta: {
        pageTitle: "Scanners",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Scanners",
            active: true,
          },
        ],
      },
    },
    {
      path: "/scanner/add",
      name: "Add Scanner",
      component: () => import("@/views/Scanner/add.vue"),
      meta: {
        pageTitle: "Add Scanner",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add Scanner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/scanner/edit/:id",
      name: "Edit Scanner",
      component: () => import("@/views/Scanner/edit.vue"),
      meta: {
        pageTitle: "Edit Scanner",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit Scanner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/scans/",
      name: "List Scans",
      component: () => import("@/views/Scan/index.vue"),
      meta: {
        pageTitle: "Scans",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Scans",
            active: true,
          },
        ],
      },
    },
    {
      path: "/schedules/",
      name: "List Schedules",
      component: () => import("@/views/Scan/schedule/index.vue"),
      meta: {
        pageTitle: "Schedules",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reports/",
      name: "List Reports",
      component: () => import("@/views/Reports/index.vue"),
      meta: {
        pageTitle: "Reports",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/uploadReport",
      name: "Add Reports",
      component: () => import("@/views/Reports/uploadReport.vue"),
      meta: {
        pageTitle: "Upload Report",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Upload",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/report-log/:id",
      name: "Report Logs",
      component: () => import("@/views/Reports/reportLog.vue"),
      meta: {
        pageTitle: "Report Logs",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/templates",
      name: "List Templates",
      component: () => import("@/views/Reports/Templates/index.vue"),
      meta: {
        pageTitle: "Templates",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/scheduled-reports",
      name: "List Scheduled Reports",
      component: () => import("@/views/Reports/Schedules/index.vue"),
      meta: {
        pageTitle: "Automated Reports",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/add-scheduled-report",
      name: "Add Scheduled Reports",
      component: () => import("@/views/Reports/Schedules/add.vue"),
      meta: {
        pageTitle: "Scheduled Reports",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/template/add",
      name: "Add Template",
      component: () => import("@/views/Reports/Templates/add.vue"),
      meta: {
        pageTitle: "Templates",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/template/edit/:id",
      name: "Edit Template",
      component: () => import("@/views/Reports/Templates/edit.vue"),
      meta: {
        pageTitle: "Templates",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report/template/preview/:id",
      name: "Preview Template",
      component: () => import("@/views/Reports/Templates/preview.vue"),
      meta: {
        pageTitle: "Templates",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    //End of Scanner

    // start projects
    {
      path: "/projects",
      name: "List Projects",
      component: () => import("@/views/Projects/list.vue"),
      meta: {
        pageTitle: "Projects",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/projects/add-project",
      name: "Add Projects",
      component: () => import("@/views/Projects/add.vue"),
      meta: {
        pageTitle: "Projects",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/projects/edit-project/:id",
      name: "Edit Project",
      component: () => import("@/views/Projects/edit.vue"),
      meta: {
        pageTitle: "Projects",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },

    // end projects
    // start activity

    {
      path: "/Activity",
      name: "List Activity",
      component: () => import("@/views/Projects/Activity/list.vue"),
      meta: {
        pageTitle: "Activity List",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Activity/add-activity",
      name: "Add Activity",
      component: () => import("@/views/Projects/Activity/add.vue"),
      meta: {
        pageTitle: "Add Activity",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Activity/edit-activity/:id",
      name: "Edit Activity",
      component: () => import("@/views/Projects/Activity/edit.vue"),
      meta: {
        pageTitle: "Edit Activity",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    // end activity
    {
      path: "/Agent",
      name: "Agent List",
      component: () => import("@/views/Agent/index.vue"),
      meta: {
        pageTitle: "Agent List",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/agent/edit/:id",
      name: "Edit Agent",
      component: () => import("@/views/Agent/edit.vue"),
      meta: {
        pageTitle: "Agent",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },

    // features
    {
      path: "/Features",
      name: "Feature List",
      component: () => import("@/views/Features/index.vue"),
      meta: {
        pageTitle: "Feature List",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    // end feature

    // Discovery
    {
      path: "/Discovery",
      name: "Discovery List",
      component: () => import("@/views/Discovery/index.vue"),
      meta: {
        pageTitle: "Discovery",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Index",
            active: true,
          },
        ],
      },
    },

    // insecure port
    {
      path: "/Insecure-port",
      name: "Insecure-port List",
      component: () => import("@/views/InsecurePort/insecure-port-list.vue"),
      meta: {
        pageTitle: "Insecure Port",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Insecure-port/add",
      name: "Insecure-port Add",
      component: () => import("@/views/InsecurePort/insecure-port-add.vue"),
      meta: {
        pageTitle: "Insecure Port",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Insecure-port/edit/:id",
      name: "Insecure-port Edit",
      component: () => import("@/views/InsecurePort/insecure-port-edit.vue"),
      meta: {
        pageTitle: "Insecure Port",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    // end insecure port

    // nessus scan policy

    {
      path: "/nessus-profile",
      name: "Nessus Profile",
      component: () => import("@/views/ScanPolicy/index.vue"),
      meta: {
        pageTitle: "Nessus Profile",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },

    // end nessus scan policy
    // Deleted Items Section

    {
      path: "/organization-delete-list",
      name: "organization-delete-list",
      component: () => import("@/views/DeletedItems/orgDelete.vue"),
      meta: {
        pageTitle: "Deleted Organization",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Organization",
            active: true,
          },
        ],
      },
    },
    {
      path: "/asset-delete-list",
      name: "asset-delete-list",
      component: () => import("@/views/DeletedItems/assetDelete.vue"),
      meta: {
        pageTitle: "Deleted Assets",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Assets",
            active: true,
          },
        ],
      },
    },
    {
      path: "/asset-group-delete-list",
      name: "asset-group-delete-list",
      component: () => import("@/views/DeletedItems/assetGroupDelete.vue"),
      meta: {
        pageTitle: "Deleted Asset Groups",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Asset Groups",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user-delete-list",
      name: "user-delete-list",
      component: () => import("@/views/DeletedItems/userDelete.vue"),
      meta: {
        pageTitle: "Deleted Users",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vulnerabilities-delete-list",
      name: "vulnerabilities-delete-list",
      component: () => import("@/views/DeletedItems/vulnerabilityDelete.vue"),
      meta: {
        pageTitle: "Deleted Vulnerabilities",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Vulnerabilities",
            active: true,
          },
        ],
      },
    },

    {
      path: "/organization-delete-list",
      name: "organization-delete-list",
      component: () => import("@/views/DeletedItems/orgDelete.vue"),
      meta: {
        pageTitle: "Deleted Organization",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Organization",
            active: true,
          },
        ],
      },
    },
    {
      path: "/asset-delete-list",
      name: "asset-delete-list",
      component: () => import("@/views/DeletedItems/assetDelete.vue"),
      meta: {
        pageTitle: "Deleted Assets",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Assets",
            active: true,
          },
        ],
      },
    },
    {
      path: "/asset-group-delete-list",
      name: "asset-group-delete-list",
      component: () => import("@/views/DeletedItems/assetGroupDelete.vue"),
      meta: {
        pageTitle: "Deleted Asset Groups",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Asset Groups",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user-delete-list",
      name: "user-delete-list",
      component: () => import("@/views/DeletedItems/userDelete.vue"),
      meta: {
        pageTitle: "Deleted Users",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vulnerabilities-delete-list",
      name: "vulnerabilities-delete-list",
      component: () => import("@/views/DeletedItems/vulnerabilityDelete.vue"),
      meta: {
        pageTitle: "Deleted Vulnerabilities",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Deleted Vulnerabilities",
            active: true,
          },
        ],
      },
    },
    // wallet
    {
      path: "/wallet",
      name: "Wallet",
      component: () => import("@/views/Credit/index.vue"),
      meta: {
        requiresAuth: true,
        // breadcrumb: [
        //   {
        //     text: "Index",
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/wallet/:id/:ref_id",
      name: "Credit Balance",
      component: () => import("@/views/Credit/index.vue"),
      meta: {
        requiresAuth: true,
        // breadcrumb: [
        //   {
        //     text: "Index",
        //     active: true,
        //   },
        // ],
      },
    },
    // end wallet

    // Agent Configuration
    {
      path: "/add-agent-configuration/:id",
      name: "Agent Configuration",
      component: () => import("@/views/Credit/AgentConfiguration/add.vue"),
      meta: {
        pageTitle: "Agent Configuration ",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },

    {
      path: "/edit-agent-configuration/:id",
      name: "Edit Agent Configuration",
      component: () => import("@/views/Credit/AgentConfiguration/edit.vue"),
      meta: {
        pageTitle: "Agent Configuration ",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },

    // end Agent Configuration
    //teams\
    //Start assets
    {
      path: "/team",
      name: "Teams",
      component: () => import("@/views/Team/index.vue"),
      meta: {
        pageTitle: "User Teams",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/team/add",
      name: "Add Team",
      component: () => import("@/views/Team/add.vue"),
      meta: {
        pageTitle: "Add Team",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/team/edit/:id",
      name: "Edit Team",
      component: () => import("@/views/Team/edit.vue"),
      meta: {
        pageTitle: "Team",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/team/manage/:id",
      name: "Manage Team",
      component: () => import("@/views/Team/manage.vue"),
      meta: {
        pageTitle: "Team",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Manage",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/task/my-tasks',
    //   name: 'My Tasks',
    //   component: () => import('@/views/Task/deprecated/index.vue'),
    //   meta: {
    //     pageTitle: 'Tasks',
    //     requiresAuth: true,
    //     breadcrumb: [
    //       {
    //         text: 'My Tasks',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // network location

    {
      path: "/network-location",
      name: "Network-Location",
      component: () => import("@/views/NetworkLocation/index.vue"),
      meta: {
        pageTitle: "Network Location",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },

    // end network location
    {
      path: "/public-sign-up",
      name: "public-sign-up",
      component: () => import("@/views/PublicSignUp/index.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/2FA/:id",
      name: "2FA",
      component: () => import("@/views/2FA.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/app-switch",
      name: "app-switch",
      props: true,
      component: () => import("@/views/AppSwitchLanding.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/signup",
      name: "page-sign-up",
      component: () => import("@/views/Registration.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password-form",
      component: () => import("@/views/forgot_password.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/no-workspace",
      name: "noworkspace",
      component: () => import("@/views/noworkspace.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    {
      path: "/no-feature",
      name: "nofeature",
      component: () => import("@/views/NoFeature.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    {
      path: "/expired",
      name: "expired",
      component: () => import("@/views/expired.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/on-boarding",
      name: "on-boarding",
      component: () => import("@/views/onBoardingRedirection.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reset-password",
      name: "forgot-password",
      component: () => import("@/views/reset_password.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/access-denied",
      name: "error-403",
      component: () => import("@/views/error/Error403.vue"),
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

// Custom navigation guard that returns a Promise
function waitForAnyStorageEvent() {
  return new Promise((resolve) => {
    const storageEventHandler = () => {
      // Remove the event listener after any storage event is received
      window.removeEventListener("storage", storageEventHandler);
      resolve();
    };

    // Listen for the 'storage' event to detect when any storage event occurs
    window.addEventListener("storage", storageEventHandler);
  });
}

router.beforeEach((to, from, next) => {
  const non_organization_bounded_paths = [
    "/user/logs",
    "/login",
    "/dashboard",
    "/organizations",
    "/organization",
    "/scanners",
    "/scanner",
    "/roles",
    "/users",
    "/user",
    "/wallet",
    "/Agent",
    "/nessus-profile",
  ];

  // console.log(from.path,"--", to.path);
  console.log("From", from.path);
  console.log("To", to.path);
  console.log("ORG_ID", store.state.app.org_id);
  console.log(
    "Token Exist:",
    $cookies.get("token") && $cookies.get("token") != "null",
    $cookies.get("token")
  );

  // if (
  //   to.path == "/login" &&
  //   $cookies.get("token") &&
  //   $cookies.get("token") != "null"
  // ) {
  //   next("/dashboard");
  //   return;
  // }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var timeNow = new Date();
    var timeLoggedIn = new Date(localStorage.getItem("loggedInTime"));
    var minutes = (timeNow.getTime() - timeLoggedIn.getTime()) / 1000 / 60;
    console.log("time", Math.ceil(minutes));
    console.log("Logedd Inn", store.getters["app/isLoggedIn"]);
    minutes = Math.ceil(minutes);
    if (minutes > 5 && minutes < 15)
      // alert("refredh")
      store.dispatch("app/refresh", store.state.app.token);

    if (store.getters["app/isLoggedIn"]) {
      console.log("logged in");
      console.log("Main Session", store.getters["app/isMainSession"]);
      if (store.getters["app/isMainSession"]) {
        next("/app-switch");
        return;
      }

      //If user is staff has not selected any organization, user is redirected to home where a popup appears for selection
      if (
        store.state.app.user.is_staff &&
        store.state.app.org_id === null &&
        !non_organization_bounded_paths.some(
          (path) => to.path.includes(path) || to.path == path
        )
      ) {
        if (
          !non_organization_bounded_paths.some(
            (path) => to.path.includes(path) || to.path == path
          ) &&
          store.state.app.redirectedRoute == null
        ) {
          store.dispatch("app/setRedirectedRoute", to.path);
        }
        next("/dashboard");
        return;
      } else if (
        store.state.app.org_id !== null &&
        store.state.app.redirectedRoute !== null
      ) {
        const newRoute = store.state.app.redirectedRoute;
        store.dispatch("app/setRedirectedRoute", null);
        next(newRoute);
        return;
      }
      next();
      return;
    }
    console.log("not logged in");
    localStorage.setItem("from_url", to.path);
    next("/login");
  } else if (
    to.path == "/login" &&
    $cookies.get("token") &&
    $cookies.get("token") != "null"
  ) {
    next("/dashboard");
  } else {
    next();
  }
});
export default router;

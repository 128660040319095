import router from "@/router";
import Vue from "vue";
import { $themeBreakpoints } from "@themeConfig";
import axios from "axios";

function initialState() {
  if (
    window.localStorage.getItem("TM_CREDENTIALS_SHARING") &&
    window.localStorage.getItem("TM_CREDENTIALS_SHARING") != null
  ) {
    let newState = {
      windowWidth: 0,
      acceptRedirectPath: true,
      ...JSON.parse(window.localStorage.getItem("TM_CREDENTIALS_SHARING")),
    };
    newState["isSessionProvider"] = false;
    window.localStorage.removeItem("TM_CREDENTIALS_SHARING");
    return newState;
  } else {
    return {
      windowWidth: 0,
      acceptRedirectPath: true,
      isSessionProvider: true,
      shallShowOverlay: false,
      status: "",
      token: null,
      organizations: [{ value: null, text: "--Select Organization--" }],
      organizationList: [{ value: null, text: "--Select Organization--" }],
      organizationId: null,
      starting_month: null,
      enabled_modules: [],
      expired: false,
      user: {
        id: "",
        username: "",
        groups: [],
        fullname: "",
        email: "",
        is_active: false,
        is_staff: false,
        first_name: "",
        last_name: "",
        profile: {
          organization: null,
          is_organization_owner: false,
          o_profile: null,
        },
        permissions: [],
        workspaces: [],
        role: "",
      },
      isAdmin: false,
      uid: null,
      org_id: null,
      redirectedRoute: null,
      isPublic: false,
      orgAssetCount: 0,
      currentModules: [],
      currentWorkspace: null,
      workspaces: [],
      is_onboading_completed: null,
    };
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE: (state) => {
      // acquire initial state
      const exludedList = ["windowWidth"];
      const s = initialState();
      Object.keys(s).forEach((key) => {
        if (exludedList.includes(key) == false) {
          state[key] = s[key];
        }
      });
    },
    UPDATE_STATE: (state, newState) => {
      // acquire initial state
      const exludedList = ["windowWidth"];
      if (newState && newState != null && typeof newState != "undefined") {
        Object.keys(newState).forEach((key) => {
          if (exludedList.includes(key) == false) {
            state[key] = newState[key];
          }
        });
      }
    },
    UPDATE_SESSION_PROVIDER_STATUS(state, val) {
      state.isSessionProvider = val;
    },
    UPDATE_REDIRECTED_ROUTE(state, val) {
      state.redirectedRoute = val;
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_ACCEPT_REDIRECT_PATH(state, value) {
      state.acceptRedirectPath = value;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
      $cookies.set("token", token, null, null, process.env.VUE_APP_DOMAIN);
    },
    auth_error(state) {
      state.status = "error";
    },
    auth_isAdmin(state) {
      state.isAdmin = true;
    },
    set_userData(state, user) {
      state.user = user;
      //debugger
      state.user.fullname = user.first_name + " " + user.last_name;
    },
    sso_user_ref_id(state, user_ref_id) {
      $cookies.set("uid", user_ref_id, null, null, process.env.VUE_APP_DOMAIN);
      state.uid = user_ref_id;
    },
    set_userAttributes(state, permissions) {
      console.log("permissions", permissions);
      let oldData = state.user;
      state.user = { ...oldData, permissions: permissions };
      // Vue.set(state.user,'permissions2',permissions);
      //debugger
    },
    set_role(state, role) {
      state.user.role = role;
    },
    set_org(state, org_id) {
      state.org_id = org_id;
      // $cookies.set("cid", org_id, null, null, process.env.VUE_APP_DOMAIN);
    },
    // set_organizations(state, organizations) {
    //   state.organizations = organizations;
    // },
    setEnabled_modules: (state, enabled_modules) => {
      state.enabled_modules = enabled_modules;
    },
    // set_organizationList(state, organizationList) {
    //   state.organizationList = organizationList;
    // },
    set_workspace(state, workspaces) {
      // Check if TM module is enabled for each tenant and then load to workspaces
      let f_workspaces = [];
      if (workspaces != null) {
        workspaces.map((item) => {
          if (item.enabled_modules.constructor === Object)
            item.enabled_modules = [item.enabled_modules];

          item.enabled_modules.map((module) => {
            if ("name" in module) {
              if (module.name == "Threat Management") f_workspaces.push(item);
            }
          });
        });
      }
      state.workspaces = f_workspaces;
      // If selected organization is not there in the current workspaces list
      // Then force reload
      if (workspaces != null) {
        let matched = 0;
        workspaces.map((item) => {
          if (item.tenant_uid == state.org_id) matched = matched + 1;
        });
        // if(matched == 0)
        console.log("Matched", matched);
      }
    },
    set_organizationId(state, organizationId) {
      state.organizationId = organizationId;
    },
    set_starting_month(state, starting_month) {
      state.starting_month = starting_month;
    },
    set_isPublic(state, isPublic) {
      state.isPublic = isPublic;
    },
    set_orgAssetCount(state, orgAssetCount) {
      state.orgAssetCount = orgAssetCount;
    },
    set_currentWorkspace(state, workspace) {
      state.currentWorkspace = workspace;
    },
    set_currentModules(state, mod) {
      state.currentModules = mod;
    },
    set_onboading_completed(state, isOnboardingCompleted) {
      state.is_onboading_completed = isOnboardingCompleted;
    },
    // set_onboading_completed(state, is_onboading_completed) {
    //   state.is_onboading_completed = is_onboading_completed;
    // },
    // setOrgnizationId
    logout(state) {
      // commit("set_organizationId" , "");
      state.organizationId = null;
      state.starting_month = null;
      state.status = "";
      state.token = null;
      state.isAdmin = false;
      state.org_id = null;
      state.actualPath = null;
      state.uid = null;
      state.organizations = [{ value: null, text: "--Select Organization--" }];
      state.enabled_modules = [];
      state.organizationList = [
        { value: null, text: "--Select Organization--" },
      ];
      state.workspaces = [];
      state.isPublic = false;
      state.expiry = true;
      state.orgAssetCount = 0;
      state.currentModules = [];
      state.currentWorkspace = null;
      state.is_onboading_completed = null;
      state.user = {
        id: "",
        username: "",
        groups: [],
        fullname: "",
        email: "",
        is_active: false,
        is_staff: false,
        first_name: "",
        last_name: "",
        profile: {
          organization: null,
          is_organization_owner: false,
          o_profile: null,
        },
        permissions: [],
      };
      $cookies.set("uid", null, null, null, process.env.VUE_APP_DOMAIN);
    },
    // Add log for user Logins
    // addUserLog(state, user) {
    //   let data = {
    //     activity_on: "USER",
    //     log_message: `${user.username} User Loged In.`,
    //   };
    //   const BASE_URL = process.env.VUE_APP_BASEURL;
    //   const headers = {
    //     "content-type": "application/json",
    //   };
    //   axios({
    //     url: BASE_URL + "logs/v1/logs/",
    //     data: data,
    //     method: "POST",
    //     headers: headers,
    //   });
    // },
    // Add logs for user logins.
    getOrgId(state) {
      // const options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_BASEURL +
      //     "organization/organization/get-org-with-refid",
      // };
      const BASE_URL = process.env.VUE_APP_BASEURL;
      const headers = {
        "content-type": "application/json",
      };
      axios({
        url: BASE_URL + "organization/organization/get-org-with-refid",
        // data: data,
        method: "GET",
        headers: headers,
      }).then((res) => {
        var organizationId = JSON.stringify(res.data.org_id);
        commit("setorganizationIdStore", organizationId);
        // state.organizationId = organizationId;
      });
      // var self = this;
      // this.$http(options).then((res) => {
      //   var organizationId = JSON.stringify(res.data.org_id);

      //   state.organizationId = organizationId;
      //   // self.isPublic = res.data.is_public_login;
      //   // self.orgAssetCount = res.data.asset_count;
      //   // self.checkPublicSignUp();
      // });
      // this.$store.dispatch("app/setorganizationIdStore", this.organizationId);
      // this.getOrganisationDetail();
    },
    checkifanymatchingworkspacesinthelist(state, org_id) {
      let matched = 0;
      state.workspaces.map((item) => {
        if (item.tenant_uid == org_id) matched = matched + 1;
      });
      console.log("matched", matched);
      // Meaning user has no access to the current workspace
      if (matched == 0) {
        state.org_id = null;
        window.location.reload();
      } else state.org_id = org_id;
    },
    checkIfUserChanged(state) {
      if (state.uid == null && $cookies.get("uid") != null) {
        //debugger
        state.uid = $cookies.get("uid");
      }
      if (state.uid != null && state.uid != $cookies.get("uid")) {
        //debugger
        // commit("sso_user_ref_id", $cookies.get("uid"));
        state.uid = $cookies.get("uid");
        state.org_id = null;
        // window.location.reload();
      }
    },
    set_expiry(state, expiry) {
      state.expiry = expiry;
    },
  },
  actions: {
    setRedirectedRoute({ commit }, data) {
      commit("UPDATE_REDIRECTED_ROUTE", data);
    },

    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        const BASE_URL = process.env.VUE_APP_BASEURL;
        console.log(BASE_URL);
        commit("auth_request");
        axios({ url: BASE_URL + "api-token-auth/", data: user, method: "POST" })
          .then((resp) => {
            if (resp.data.token) {
              const token = resp.data.token;
              const user = resp.data.userData;
              const workspaces = resp.data.workspaces;

              // localStorage.setItem('token', token)
              localStorage.setItem("userid", user.id);
              localStorage.setItem("loggedInTime", new Date());
              axios.defaults.headers.common["Authorization"] = token;
              commit("auth_success", token);
              commit("set_userData", user);
              commit("set_expiry", false);
              commit("sso_user_ref_id", resp.data.user_ref_id);
              // commit("set_userAttributes", resp.data.permissions);
              commit("set_workspace", workspaces);
              commit("setEnabled_modules", resp.data.enabled_modules);
              // adding user logs
              // commit("addUserLog", user);
              if (user.is_staff || user.groups[0].role_type == 0)
                commit("auth_isAdmin");
              resolve(resp);
            } else if (resp.data.data.is_2fa_enabled == 1) {
              router.push({
                name: "2FA",
                params: { id: resp.data.data.first_auth_token },
              });
            }
            // else {
            //   this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: "Login Failed",
            //       icon: "ErrorIcon",
            //       variant: "danger",
            //     },
            //   });
            // }

            // }
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        $cookies.set("token", null, null, null, process.env.VUE_APP_DOMAIN);
        $cookies.set("uid", null, null, null, process.env.VUE_APP_DOMAIN);
        commit("RESET_STATE");
        sessionStorage.clear();
        localStorage.removeItem("token"); //if any
        localStorage.removeItem("loggedInTime");
        localStorage.removeItem("workspaces");
        delete axios.defaults.headers.common["Authorization"];
        // document.cookie = "sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // document.cookie = "csrftoken=; expi  res=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        resolve();
      });
    },
    getOrg({ commit }, org_id) {
      commit("set_org", org_id);

      if (org_id != null) {
        const BASE_URL = process.env.VUE_APP_BASEURL;

        // First API call to get user permissions and role
        axios({
          method: "GET",
          headers: { "content-type": "application/json", "org-id": org_id },
          url: BASE_URL + "user/user-permissions",
        })
          .then((res) => {
            const permissions = JSON.parse(res.data.permissions);
            const role = res.data.role;

            commit("set_userAttributes", permissions);
            commit("set_role", role);
            // debugger;
            // Second API call to get organization details
            axios({
              url: BASE_URL + "organization/organization/get-org-with-refid",
              method: "GET",
              headers: { "content-type": "application/json", "org-id": org_id },
            })
              .then((res) => {
                const organizationId = JSON.stringify(res.data.org_id);
                const isPublic = res.data.is_public_login;
                const orgAssetCount = res.data.asset_count;
                const starting_month = res.data.starting_month;
                const is_onboading_completed = res.data.is_onboading_completed;

                commit("set_expiry", false);
                commit("set_organizationId", organizationId);
                commit("set_isPublic", isPublic);
                commit("set_orgAssetCount", orgAssetCount);
                commit("set_starting_month", starting_month);
                commit("set_onboading_completed", is_onboading_completed);
                // Optionally, reload the page if necessary
                window.location.reload();
              })
              .catch((error) => {
                console.error("Error fetching organization details:", error);
                // Handle the error appropriately
              });
          })
          .catch((error) => {
            console.error("Error fetching user permissions:", error);
            // Handle the error appropriately
          });
      }
    },

    getOrgOLD({ commit }, org_id) {
      commit("set_org", org_id);
      if (org_id != null) {
        //Setting the org permissions
        const BASE_URL = process.env.VUE_APP_BASEURL;
        // const headers = {
        //   "content-type": "application/json",
        //   "org-id": org_id,
        // };
        var self = this;
        axios({
          url: BASE_URL + "user/user-permissions",
          method: "GET",
          headers: { "content-type": "application/json", "org-id": org_id },
        }).then((res) => {
          console.log("Role", res.data);
          // commit("set_org", org_id);
          var permissions = JSON.parse(res.data.permissions);
          var role = res.data.role;
          commit("set_userAttributes", permissions);
          commit("set_role", role);
          axios({
            url: BASE_URL + "organization/organization/get-org-with-refid",
            method: "GET",
            headers: { "content-type": "application/json", "org-id": org_id },
          }).then((res) => {
            var organizationId = JSON.stringify(res.data.org_id);
            var isPublic = res.data.is_public_login;
            var orgAssetCount = res.data.asset_count;
            var starting_month = res.data.starting_month;
            var is_onboading_completed = res.data.is_onboading_completed;
            commit("set_expiry", false);
            commit("set_organizationId", organizationId);
            commit("set_isPublic", isPublic);
            commit("set_orgAssetCount", orgAssetCount);
            commit("set_starting_month", starting_month);
            commit("set_onboading_completed", is_onboading_completed);
            // self.checkPublicSignUp();
            //debugger
            window.location.reload();
          });
        });
      }
    },
    // checkifanymatchingworkspacesinthelist({ commit, state }, org_id){
    //   let matched = 0
    //   state.workspaces.map((item) => {
    //     if(item.tenant_uid == org_id)
    //         matched = matched+ 1
    //   })
    //   console.log("matched",matched)
    //   // Meaning user has no access to the current workspace
    //   if(matched == 0){
    //     commit("set_org", null);
    //     window.location.reload();
    //   }
    // },
    getOrgRefresh({ commit, state }, org_id) {
      //commit("checkifanymatchingworkspacesinthelist", org_id);
      commit("checkIfUserChanged");
      if (org_id != null) {
        const BASE_URL = process.env.VUE_APP_BASEURL;
        const headers = {
          "content-type": "application/json",
          "org-id": org_id,
        };
        var self = this;
        axios({
          url: BASE_URL + "user/user-permissions",
          method: "GET",
          headers: headers,
        }).then((res) => {
          //debugger
          console.log("ROLE", role);
          var permissions = JSON.parse(res.data.permissions);
          var role = res.data.role;
          commit("set_userAttributes", permissions);
          commit("set_role", role);
          axios({
            url: BASE_URL + "organization/organization/get-org-with-refid",
            method: "GET",
            headers: headers,
          }).then((res) => {
            var organizationId = JSON.stringify(res.data.org_id);
            var isPublic = res.data.is_public_login;
            var orgAssetCount = res.data.asset_count;
            var starting_month = res.data.starting_month;
            var is_onboading_completed = res.data.is_onboading_completed;
            commit("set_expiry", false);
            commit("set_organizationId", organizationId);
            commit("set_isPublic", isPublic);
            commit("set_orgAssetCount", orgAssetCount);
            commit("set_starting_month", starting_month);
            commit("set_onboading_completed", is_onboading_completed);
          });
        });
      }
    },
    // setOrgsInStore({ commit }, organizations) {
    //   commit("set_organizations", organizations);
    // },
    // setOrgListInStore({ commit }, organizationList) {
    //   commit("set_organizationList", organizationList);
    // },
    setorganizationIdStore({ commit }, organizationId) {
      commit("set_organizationId", organizationId);
    },
    setPermissionInStore({ commit }, permissions) {
      commit("set_userAttributes", permissions);
    },
    setExpiry({ commit, state }, expired) {
      commit("set_expiry", expired);
    },
    refresh({ commit }, token) {
      return new Promise((resolve, reject) => {
        let data = {
          token: token,
        };
        // const BASE_URL = process.env.VUE_APP_BASEURL_REFRESH;
        const BASE_URL = process.env.VUE_APP_BASEURL;
        axios({
          url: BASE_URL + "api-token-refresh/",
          data: data,
          method: "POST",
        }).then((resp) => {
          const token = resp.data.token;
          const user = resp.data.userData;
          const workspaces = resp.data.workspaces;
          // localStorage.setItem('token', token)
          localStorage.setItem("userid", user.id);
          localStorage.setItem("loggedInTime", new Date());
          axios.defaults.headers.common["Authorization"] = token;
          commit("auth_success", token);
          commit("set_userData", user);
          commit("set_userAttributes", resp.data.permissions);
          commit("set_expiry", false);
          //commit("set_workspace", workspaces);
          if (user.is_staff) commit("auth_isAdmin");
          resolve(resp);
        });
      });
    },
    reloadCheckCookie({ commit }, token) {
      return new Promise((resolve, reject) => {
        let data = {
          token: token,
        };
        // const BASE_URL = process.env.VUE_APP_BASEURL_REFRESH;
        const BASE_URL = process.env.VUE_APP_BASEURL;
        axios({
          url: BASE_URL + "api-token-refresh/",
          data: data,
          method: "POST",
        }).then((resp) => {
          // const token = resp.data.token;
          const user = resp.data.userData;
          const workspaces = resp.data.workspaces;
          // localStorage.setItem('token', token)
          localStorage.setItem("userid", user.id);
          localStorage.setItem("loggedInTime", new Date());
          commit("set_userData", user);
          commit("set_userAttributes", resp.data.permissions);
          commit("set_workspace", workspaces);
          commit("set_expiry", false);
          if (user.is_staff) commit("auth_isAdmin");
          resolve(resp);
        });
      });
    },
    // currenUser({ commit }, token) {
    //   return new Promise((resolve, reject) => {
    //     const BASE_URL = process.env.VUE_APP_SECUSY_API_URL;
    //     axios({
    //       url: BASE_URL + "current-user",
    //       method: "GET",
    //     }).then((resp) => {
    //       const workspaces = resp.data.data.workspaces;
    //       localStorage.setItem("loggedInTime", new Date());
    //       commit("set_workspace", workspaces);
    //       resolve(resp);
    //     });
    //   });
    // },
    currenUser({ commit, state }, token) {
      //debugger
      //commit("checkIfUserChanged")
      return new Promise((resolve, reject) => {
        const BASE_URL = process.env.VUE_APP_BASEURL;
        axios({
          url: BASE_URL + "app-switcher-user/",
          method: "GET",
        }).then((resp) => {
          const token = resp.data.token;
          const user = resp.data.userData;
          const workspaces = resp.data.workspaces;
          // const isPublic = resp.data.is_public_login;
          // const currentCid = $cookies.get("cid");
          if (state.org_id && state.org_id !== null) {
            const currentCid = state.org_id;
            // commit("set_isPublic", isPublic);
            workspaces.map((item) => {
              if (item.tenant_uid === currentCid) {
                commit("set_currentWorkspace", item);
                const currentmodule = [];
                item.enabled_modules.map((mod) => {
                  currentmodule.push(mod.name);
                });
                commit("set_currentModules", currentmodule);
              }
            });
          }

          // localStorage.setItem('token', token)
          localStorage.setItem("userid", user.id);
          localStorage.setItem("loggedInTime", new Date());
          commit("auth_success", token);
          commit("set_userData", user);
          commit("set_userAttributes", resp.data.permissions);
          commit("set_expiry", false);
          commit("set_workspace", workspaces);
          // //debugger
          commit("setEnabled_modules", resp.data.enabled_modules);
          // commit("addUserLog", user);
          if (user.is_staff || user.groups[0].role_type == 0)
            commit("auth_isAdmin");
          resolve(resp);
        });
      });
    },
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    isLoggedIn: (state) => {
      if (!!state.token) {
        return true;
      } else if ($cookies.get("token") && $cookies.get("token") != "null") {
        return true;
      }
      return false;
    },

    isMainSession: (state) => {
      if (!state.token && state.isSessionProvider === true) {
        return true;
      }
      return false;
    },

    authStatus: (state) => state.status,
    isAdmin: (state) => state.isAdmin,
    token: (state) => state.token,
    org_id: (state) => state.org_id,
    workspaces: (state) => state.workspaces,
    organizationId: (state) => state.organizationId,
    starting_month: (state) => state.starting_month,
    is_onboading_completed: (state) => state.is_onboading_completed,
  },
};

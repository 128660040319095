import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import panZoom from "vue-panzoom";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import VueHotkey from "v-hotkey";
import VueCookies from "vue-cookies";

Vue.prototype.$cookies = VueCookies;

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import Axios from "axios";

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";

// Setup SocketIO
/* Establish Connection */
// console.log("Socket URL",process.env.VUE_APP_SOCKETURL)
const socketConnection = SocketIO(process.env.VUE_APP_SOCKETURL, {
  auth: { token: store.state.app.token },
  // Enable this for socket io to work in local environ
  // transports : ['websocket']
  
});
Vue.use(panZoom);
Vue.use(VueHotkey);

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: socketConnection,
    vuex: { store, actionPrefix: "SOCKET_", mutationPrefix: "SOCKET_" },
    //options: { path: "/secusy-dev" } //Optional options
  })
);

//Set up Axios
//Ref : https://www.digitalocean.com/community/tutorials/handling-authentication-in-vue-using-vuex
Vue.prototype.$http = Axios;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

// Broadcasting to check if any tabs with TM app already exist in the browser window
window.localStorage.setItem(
  "TM_REQUESTING_SHARED_CREDENTIALS",
  Date.now().toString()
);
window.localStorage.removeItem("TM_REQUESTING_SHARED_CREDENTIALS");

// Event Listener to Provide the current session to any requesting tab instances or to capture session sent from any other tab instances
window.addEventListener("storage", (event) => {
  // IF session dosnt exists and the Broadcasted key is TM_CREDENTIALS_SHARING then the incoming session is stored in the session storage
  if (
    event.key === "TM_CREDENTIALS_SHARING" &&
    $cookies.get("token") &&
    $cookies.get("token") != "null" &&
    $cookies.get("token") != store.state.app.token
  ) {
    if (event.newValue && event.newValue != null) {
      const session = JSON.parse(event.newValue);
      if (session && session != null && typeof session != "undefined") {
        store.commit("app/UPDATE_STATE", session);
        window.localStorage.removeItem("TM_CREDENTIALS_SHARING");
      }
    }
  }

  // Checks if TM_REQUESTING_SHARED_CREDENTIALS is being sent from other tabs & Broadcasts the session if the session exist in the current tab
  if (
    event.key === "TM_REQUESTING_SHARED_CREDENTIALS" &&
    $cookies.get("token") &&
    $cookies.get("token") != "null" &&
    store.state.app.token &&
    store.state.app.token != null &&
    store.state.app.org_id &&
    store.state.app.org_id != null &&
    !window.localStorage.getItem("TM_CREDENTIALS_SHARING")
  ) {
    let newSession = { ...store.state.app };
    newSession["isSessionProvider"] = false;
    const session = JSON.stringify(newSession);
    window.localStorage.setItem("TM_CREDENTIALS_SHARING", session);
    setTimeout(() => {
      window.localStorage.removeItem("TM_CREDENTIALS_SHARING");
    }, 5000);
  }
});

// Waits for the Storage event listeners to instantiate before mounting the Vue instance
setTimeout(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}, 800);

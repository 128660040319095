import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import notification from "./notification";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    notification,
    appConfig,
    verticalMenu,
  },
  plugins: [
    // createPersistedState()
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  strict: process.env.DEV,
});
